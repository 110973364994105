import React, { FormEvent, useEffect } from 'react';
import { Col, Form, Input, Label, Row } from 'reactstrap';
import themeStore from '../../../../../core-ui/models/ThemeStore';
import { useAuthContext, User } from '../../../../../utils/auth';
import { dateFromId, isIdentifiableLoaded } from '../../../../../utils/common';
import {
	buildTemplateUrl,
	buildWorkflowCollectionUrl,
} from '../../../../../utils/common/links';
import { _logError } from '../../../../../utils/common/log';
import {
	EntityPropList,
	EntityPropListItem,
} from '../../../../entity-details.styled-components';
import { LabeledInput, StyledLabel, SubmitButton } from '../../../../forms';
import EntityMetadataForm from '../../../../metadata/components/entity-metadata-form.component';
import { NotificationsContext } from '../../../../notifications';
import RenderWhen from '../../../../render-when.component';
import {
	getDueDateBasedOnCurrentStages,
	getOriginalTargetDueDate,
} from '../../helpers/workflowDate.helpers';
import { useWorkflowContext } from '../../../../../context/useWorkflowStore';
import { EventWithValue } from '../../types';
import {
	EntityMetadataTemplate,
	Workflow,
	WorkflowCollection,
} from '../../types/workflow.types';
import { StatusEnum } from '../../types/workflowStatus.types';
import AddWorkflowToCollectionFormComponent from '../workflow-actions/add-workflow-to-collection-form.component';
import RemoveFromWorkflowCollectionButton from '../workflow-actions/remove-from-workflow-collection-button.component';
import UpdateWorkflowStatusForm from '../workflow-actions/update-workflow-status-form';
import {
	CustomDatePicker,
	StyledPropLink,
	WorkflowStatusPillLg,
} from './details-panel.styled-components';

type Props = {
	workflow: Workflow;
	dispatchWf: React.Dispatch<{ type: 'set'; payload: Workflow }>;
};

const DetailsPanelEditForm = (props: Props) => {
	const { info, error } = React.useContext(NotificationsContext);
	const { currentUser } = useAuthContext();
	const { updateOne, allTemplates } = useWorkflowContext();
	const { workflow, dispatchWf } = props;
	const label = themeStore._.workflow;

	const [updatedWf, setUpdatedWf] = React.useState<Workflow>(workflow);

	useEffect(() => {
		if (workflow.workflowCollection !== updatedWf.workflowCollection) {
			setUpdatedWf((updatedWf) => ({
				...updatedWf,
				workflowCollection: workflow.workflowCollection,
			}));
		}
		//eslint-disable-next-line
	}, [workflow]);

	const updateWorkflowSubmit = async (event: FormEvent) => {
		event.preventDefault();

		try {
			const updatedWorkflow = (await updateOne(
				workflow._id,
				updatedWf
			)) as Workflow;

			setUpdatedWf(updatedWorkflow);
			dispatchWf({ type: 'set', payload: updatedWorkflow });
			info(`${label} saved!`);
		} catch (e) {
			_logError(e);
			error(
				`An issue occurred while updating ${label.toLowerCase()}. Please try again later.`
			);
		}
	};

	const updateTitle = (e: any) => {
		e.preventDefault();
		setUpdatedWf({ ...updatedWf, title: e.target.value });
	};

	const renderUpdateStatusForm = () =>
		!workflow.isCompleted ? (
			<UpdateWorkflowStatusForm
				onUpdated={(updated) => setUpdatedWf(updated)}
				workflow={updatedWf}
			/>
		) : null;

	const allowUpdatingOfTitle = () =>
		currentUser.isAdmin || workflow.isCreatedByCurrentUser;

	const getTemplateUsed = () => {
		if (workflow.templateUsed) {
			if (typeof workflow.templateUsed === 'string')
				return allTemplates.find((m) => m._id === workflow.templateUsed)?.title;
			if (typeof workflow.templateUsed === 'object')
				return workflow.templateUsed.title;
		}
		return '';
	};

	const getTemplateUsedId = (): string => {
		if (workflow.templateUsed) {
			if (typeof workflow.templateUsed === 'string')
				return allTemplates.find((m) => m._id === workflow.templateUsed)
					?._id as string;
			if (typeof workflow.templateUsed === 'object')
				return workflow.templateUsed._id;
		}
		return '';
	};

	const showDueDate = () => {
		return (
			(updatedWf?.stages[0]?.status === StatusEnum.active ||
				updatedWf.stages[0]?.status === 'completed') &&
			workflow.status === 'active'
		);
	};

	return (
		<Row className="px-3">
			<Col md={7} xs={{ size: 12, order: 2 }}>
				<Form>
					<EntityPropList>
						<EntityPropListItem>
							<StyledLabel>{label} template used</StyledLabel>
							{getTemplateUsed() && (
								<div>
									<StyledPropLink
										to={buildTemplateUrl(getTemplateUsedId() as string)}
									>
										{getTemplateUsed()}
									</StyledPropLink>
								</div>
							)}
						</EntityPropListItem>

						<RenderWhen when={!!allowUpdatingOfTitle()}>
							<EntityPropListItem>
								<LabeledInput
									label={`Title`}
									type="text"
									value={updatedWf.title}
									onChange={(e: EventWithValue<string>) => updateTitle(e)}
								/>
								<Label className="ml-4" check>
									<Input
										type={'checkbox'}
										checked={updatedWf.hot?.toString() === 'true'}
										onChange={(e) =>
											setUpdatedWf({ ...updatedWf, hot: e.target.checked })
										}
									/>
									Hot Workflow
								</Label>
							</EntityPropListItem>
						</RenderWhen>

						{showDueDate() && (
							// @ts-ignore
							<EntityPropListItem>
								<StyledLabel>Original Target Completion Date</StyledLabel>
								<CustomDatePicker
									// @ts-ignore
									selected={
										new Date(getOriginalTargetDueDate(updatedWf) as string)	
									}
									disabled
									placeholderText="Click to select a date"
									// @ts-ignore
									value={getOriginalTargetDueDate(updatedWf) ?? 'TBD'}
									onChange={(e: Date) =>
										setUpdatedWf({ ...updatedWf, dueDate: e })
									}
								/>
							</EntityPropListItem>
						)}

						{showDueDate() && (
							<EntityPropListItem>
								<StyledLabel>Current Estimated Completion Date</StyledLabel>
								<CustomDatePicker
									// @ts-ignore
									selected={
										!!getDueDateBasedOnCurrentStages(updatedWf)
											? new Date(
													getDueDateBasedOnCurrentStages(updatedWf) as string
											  )
											: undefined
									}
									disabled
									placeholderText="Click to select a date"
									// @ts-ignore
									value={getDueDateBasedOnCurrentStages(updatedWf) ?? 'TBD'}
									onChange={(e: Date) =>
										setUpdatedWf({ ...updatedWf, dueDate: e })
									}
								/>
							</EntityPropListItem>
						)}

						<EntityPropListItem>
							<LabeledInput
								label={`${label} #`}
								type="text"
								value={updatedWf._id}
								disabled={true}
							/>
						</EntityPropListItem>

						<EntityPropListItem>
							<LabeledInput
								label="Created on"
								type="text"
								value={
									new Date(updatedWf.createdAt).toLocaleDateString() ??
									new Date(dateFromId(updatedWf._id)).toLocaleDateString()
								}
								disabled={true}
							/>
						</EntityPropListItem>

						<EntityPropListItem>
							<LabeledInput
								label="Created by"
								type="text"
								value={`${(updatedWf.createdBy as User).givenName} ${
									(updatedWf.createdBy as User).familyName
								}`}
								disabled={true}
							/>
						</EntityPropListItem>

						<EntityPropListItem>
							<EntityMetadataForm
								update={(updatedMeta) => {
									setUpdatedWf({ ...updatedWf, metadata: updatedMeta.payload });
								}}
								metadata={{
									...updatedWf?.metadata,
									fieldOptions:
										updatedWf?.metadata?.fieldOptions &&
										Object.keys(updatedWf.metadata.fieldOptions).some(
											(a) =>
												updatedWf?.metadata?.fieldOptions &&
												updatedWf?.metadata?.fieldOptions[a].options.length
										)
											? updatedWf.metadata?.fieldOptions
											: (updatedWf?.metadataTemplateUsed as any)?.fieldOptions
											? (updatedWf?.metadataTemplateUsed as any)?.fieldOptions
											: (updatedWf?.templateUsed as EntityMetadataTemplate)
													?.fieldOptions,
								}}
								displayTemplateField={false}
							/>
						</EntityPropListItem>
					</EntityPropList>

					<SubmitButton
						onClick={updateWorkflowSubmit}
						label={`Update ${label?.toLowerCase()}`}
						className="mt-5"
					/>
				</Form>
			</Col>
			<Col md={{ size: 4, offset: 1, order: 2 }} xs={{ size: 12, order: 1 }}>
				<h2>{label} status</h2>
				<hr />
				<EntityPropList className="mb-2 px-0">
					<EntityPropListItem>
						<StyledLabel className="mb-0 mr-2">Current status:</StyledLabel>
						<WorkflowStatusPillLg status={updatedWf.status} />
					</EntityPropListItem>

					{updatedWf.statusMsg ? (
						<EntityPropListItem>
							<StyledLabel>Current status message:</StyledLabel>
							<p>"{updatedWf.statusMsg}"</p>
						</EntityPropListItem>
					) : null}
				</EntityPropList>

				{renderUpdateStatusForm()}

				<h2 className="mt-5">{themeStore._.workflowCollection}</h2>
				<hr />

				{isIdentifiableLoaded(updatedWf.workflowCollection as Identifiable) ? (
					<EntityPropList className="mb-4">
						<EntityPropListItem>
							<StyledPropLink
								to={buildWorkflowCollectionUrl(
									updatedWf.workflowCollection as WorkflowCollection
								)}
							>
								{(updatedWf?.workflowCollection as WorkflowCollection).title}
							</StyledPropLink>
							<RemoveFromWorkflowCollectionButton
								setUpdated={(workflow: Workflow) => setUpdatedWf(workflow)}
								workflow={updatedWf}
							/>
						</EntityPropListItem>
					</EntityPropList>
				) : (
					<AddWorkflowToCollectionFormComponent workflow={updatedWf} />
				)}
			</Col>
		</Row>
	);
};

export default DetailsPanelEditForm;
