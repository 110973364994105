import {
	faCheckSquare,
	faFileUpload,
	faHandPaper,
	faImage,
	faProjectDiagram,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';
import { AssetUploadDialog } from 'components/dam-assets/components/asset-upload-dialog.component';
import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { useAuthContext } from 'utils/auth';
import { useModalCloser, useModalCreator } from 'utils/ModalStack';
import { RoleContext } from '../../context/PermissionsContext';
import RenderWhen from '../render-when.component';

// const numberOfRecentAssetsToGet: number = 12;
// const ORG = process.env.REACT_APP_ORG;

// function wfHealthReducer(
// 	state: {
// 		initialized: boolean;
// 		overdue: number;
// 		roadblocked: number;
// 		active: number;
// 		healthy: number;
// 	},
// 	action: {
// 		type: 'set' | 'update';
// 		payload: {
// 			initialized: boolean;
// 			overdue: number;
// 			roadblocked: number;
// 			active: number;
// 			healthy: number;
// 		};
// 	}
// ) {
// 	switch (action.type) {
// 		case 'set':
// 			return { ...action.payload, initalized: true };
// 		default:
// 			return state;
// 	}
// }

const WorkflowDashboard = () => {
	// state
	// const [recentWorkflows, setRecentWorkflows] = useState<Workflow[]>();
	// const [ongoingStages, setOnGoingStages] = useState<Stage[]>();
	// const [wfs, dispatch] = useReducer(wfHealthReducer, {
	// 	initialized: false,
	// 	overdue: 0,
	// 	roadblocked: 0,
	// 	active: 0,
	// 	healthy: 0,
	// });

	// hooks
	// const dashboardStore = useAxios<Workflow>('dashboard');
	// const { entities } = useWorkflowContext();
	const { currentUser } = useAuthContext();
	// const { groupsForCurrentUser } = useGroupContext();
	// const dataIsLoaded = false;

	//effects
	// useEffect(() => {
	// 	if (!wfs.initialized && !!entities) {
	// 		const wfs = entities;

	// 		if (wfs) {
	// 			console.log('entities', entities);

	// 			const isStakeholder = (workflow: Workflow) =>
	// 				flattenStages(workflow)?.some((stage) =>
	// 					stage.owners?.some(
	// 						(owner) =>
	// 							owner._id === currentUser._id ||
	// 							groupsForCurrentUser?.some((grp) => grp._id === owner._id)
	// 					)
	// 				);

	// 			const isCreator = (workflow: Workflow) =>
	// 				isEqual(workflow.createdBy?._id, currentUser._id);

	// 			const isFollower = (workflow: Workflow) =>
	// 				(workflow.templateUsed as WorkflowTemplate)?.followers?.some(
	// 					(follower) =>
	// 						follower._id === currentUser._id ||
	// 						groupsForCurrentUser?.some((grp) => grp._id === follower._id)
	// 				);

	// 			const isWorkflowFollower = (workflow: Workflow) =>
	// 				workflow?.followers?.some(
	// 					(follower) =>
	// 						follower._id === currentUser._id ||
	// 						groupsForCurrentUser?.some((grp) => grp._id === follower._id)
	// 				);

	// 			const filteredWorkflows = wfs?.filter(
	// 				(wf) =>
	// 					isCreator(wf) ||
	// 					isFollower(wf) ||
	// 					isStakeholder(wf) ||
	// 					isWorkflowFollower(wf)
	// 			);

	// 			const payload = {
	// 				overdue: filteredWorkflows?.filter(isOverdue).length,
	// 				roadblocked: filteredWorkflows?.filter(isRoadblocked).length,
	// 				healthy: filteredWorkflows?.filter(isHealthy).length,
	// 				active: filteredWorkflows?.filter(isActive).length,
	// 				initialized: true,
	// 			};
	// 			dispatch({ type: 'set', payload: payload });
	// 		}
	// 	}
	// 	// eslint-disable-next-line
	// }, [entities]);

	/* try to insert upload modal here too? duplicating admin nav modal. feels dirty */
	const modalStack = useModalCreator();
	const modalCloser = useModalCloser();
	const { canViewByRole } = React.useContext(RoleContext);

	const afterSubmit = React.useCallback(() => {
		modalCloser.closeModal();
		return navigate('/admin/dam/assets/edit-custom-upload');
	}, [modalCloser]);

	const openUploadDialog = React.useCallback(() => {
		modalStack.addModal(<AssetUploadDialog afterSubmit={afterSubmit} />);
	}, [modalStack, afterSubmit]);

	/**
	 * Handle a tile nav click event
	 * @param tile
	 */
	const onTileClick = (tile: any) => {
		switch (tile.label) {
			case 'Upload':
				openUploadDialog();
				break;
			default:
				navigate(tile.link);
		}
	};

	/**
	 * Temporary tile button navigation
	 */
	const linkTiles = [
		{
			label: 'My Assignments',
			link: '/admin/workflow/assignments',
			icon: faCheckSquare,
		},
		/* 		{
			label: 'Roadblocks',
			link: '/admin/workflow/roadblocks',
			icon: faHandPaper,
		}, */
		{
			label: 'Workflows',
			link: '/admin/workflow/workflows',
			icon: faProjectDiagram,
		},
		{
			label: 'Assets',
			link: '/admin/dam/assets',
			icon: faImage,
		},
		{
			label: 'Upload',
			link: '/admin',
			icon: faFileUpload,
		},
	];
	return (
		<Container>
			<Row>
				<Col className="p-2">
					<div className="dashboard-link-tile welcome">
						<p className="greeting label">
							Welcome, <br />
							{currentUser.givenName} {currentUser.familyName}
						</p>
					</div>
				</Col>
			</Row>
			<Row>
				{linkTiles.map((tile, index) => (
					<RenderWhen
						key={index}
						when={
							!(
								tile.label === 'Workflows' &&
								!['wfReadonly', 'wfEditable'].some(canViewByRole)
							) &&
							!(
								tile.label === 'Upload' &&
								!['damUploadable'].some(canViewByRole)
							) &&
							!(
								tile.label === 'My Assignments' &&
								!['canViewAssignments'].some(canViewByRole)
							)
						}
					>
						<Col className="p-2" key={`${tile.label}_${index}`}>
							<Button
								className="dashboard-link-tile cy-dashboard-link-tile-dashboard"
								onClick={() => onTileClick(tile)}
								key={`navtile-${index}`}
							>
								<FontAwesomeIcon icon={tile.icon} className="icon" />
								<span className="label">{tile.label}</span>
							</Button>
						</Col>
					</RenderWhen>
				))}
			</Row>
		</Container>
	);
};

export default WorkflowDashboard;
