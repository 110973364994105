import React, { useState } from 'react';
import { connectSortBy } from 'react-instantsearch-core';
import {
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	UncontrolledTooltip,
} from 'reactstrap';
import styled from 'styled-components';
import themeStore from '../../../core-ui/models/ThemeStore';

interface AssetAlgoliaSortByProperties {
	items: Array<{ label: string; value: string }>;
	onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	direction?: 'left' | 'right' | 'up' | 'down';
	label?: string | JSX.Element;
	refine?: any;
}

const CustomDropdown = styled(UncontrolledDropdown)`
	* {
		border: none !important;
	}
	.dropdown-menu.show {
		background: #fff !important;
	}
	.dropdown-toggle {
		border: none !important;
		background-color: transparent;
	}
`;
const CustomDropdownToggle = styled(DropdownToggle)`
	background: transparent;
	padding: 0;
	height: auto;
	border: none;
	position: relative;
	&:focus,
	&:hover {
		background: transparent !important;
		// border: none !important;
		border-color: red;
		box-shadow: none !important;
	}

	img {
		position: relative;
		top: -2px;
	}
`;

const assetTerm = themeStore._.asset.toLowerCase();
const pluralAssetsTerm = `${assetTerm}s`;

const renderDropdownToggle = () => (
	<div
		style={{
			background: 'transparent',
			borderRadius: 3,
			color: '#afafaf',
			marginLeft: 6,
		}}
	>
		<span id="sortAssetsButton">
			<img
				alt="Sort Assets"
				src={'/images/sort.svg'}
				style={{ height: '20px', filter: 'contrast(0.15)' }}
			/>
		</span>
		<UncontrolledTooltip target="sortAssetsButton">
			Sort {pluralAssetsTerm}
		</UncontrolledTooltip>
	</div>
);

const AssetAlgoliaSortBy = ({
	items,
	onClick,
	direction = 'up',
	label,
	refine,
}: AssetAlgoliaSortByProperties) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<CustomDropdown
			className="p-0 background-light"
			onToggle={onClick}
			direction={'down'}
			isOpen={dropdownOpen}
			toggle={toggle}
		>
			<CustomDropdownToggle color="white">
				<>{renderDropdownToggle()}</>
			</CustomDropdownToggle>
			<DropdownMenu>
				{items.map((item: { label: string; value: string }) => (
					<DropdownItem
						onClick={(event: any) =>
							refine && refine(event.target.attributes.eventkey.value)
						}
						key={item.label}
						eventkey={item.value}
					>
						{item.label}
					</DropdownItem>
				))}
			</DropdownMenu>
		</CustomDropdown>
	);
};

export default connectSortBy(AssetAlgoliaSortBy);
