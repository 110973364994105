import { UploadResult, UppyFile } from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import { Dashboard } from '@uppy/react';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { authProvider } from 'utils/core';
import {
	MultipleFileUploadProvider,
	FileSharingMultipleFileUploadProvider,
} from 'utils/core/MultipleFileUploadProvider';
import { generateID, noop, requiredValue } from '../../../utils/common';
import { _logError } from '../../../utils/common/log';
import {
	AssetVersion,
	EntityMetadataTemplate,
} from '../../workflow/workflows/types/workflow.types';

interface AssetPickerProps {
	files: UppyFile[];
	onSelect?: (result: UploadResult) => void;
	reset?: boolean;
	setReset?: (reset: boolean) => void;
	id?: string;
}

const fileSharingMultipleFileUploadProvider = new FileSharingMultipleFileUploadProvider(
	requiredValue(process.env.REACT_APP_ROME_API_ENDPOINT),
	authProvider
);

const multipleFileUploadProvider = new MultipleFileUploadProvider(
	requiredValue(process.env.REACT_APP_ROME_API_ENDPOINT),
	authProvider
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function assetFromUppyFile(file: UppyFile): AssetVersion {
	return {
		_id: '$temp_' + generateID(),
		fileName: file.name,
		type: file.extension,
		fileSizeBytes: file.size,
		path: '',
	} as AssetVersion;
}

const MultipleAssetPicker = ({
	onSelect = noop,
	files,
	reset,
	setReset,
	id,
}: AssetPickerProps) => {
	const uploadProvider = id
		? fileSharingMultipleFileUploadProvider
		: multipleFileUploadProvider;
	const { uppy } = uploadProvider;

	const [currFiles, setFiles] = React.useState({ successful: [{}] });
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [numFiles, setNumFiles] = useState(files?.length || 0);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [templateUsed, setTemplateUsed] = useState<EntityMetadataTemplate>(
		{} as EntityMetadataTemplate
	);

	useEffect(() => {
		uppy.on('complete', (file) => {
			onSelect(file);
			setFiles(file);
		});

		return () => {
			uppy.off('complete', onSelect);
			uppy.reset();
		};
	}, [uppy, onSelect]);

	useEffect(() => {
		if (reset) {
			let successFiles = currFiles.successful as UppyFile[];
			// If we remove files while looping through them, indexes shift.
			// We'll use a reference instead of source array to retain correct indexes
			const successFilesReference = cloneDeep(successFiles);
			successFilesReference.forEach((file) => clearInputSlot(file));
			setReset && setReset(false);
		}
	}, [reset]);

	/* eslint-disable */
	useEffect(() => {
		uppy.on('file-removed', (file) => {
			let successFiles = currFiles.successful as UppyFile[];
			const idx = successFiles.findIndex((f) => f.name === file.name);
			if (idx >= 0) {
				successFiles = successFiles.splice(idx, 1);
			}
			onSelect(currFiles as UploadResult);
		});
	}, [currFiles]);

	// clear only works when given
	// an empty dependency array
	const clearInputSlot = useCallback((uf: UppyFile) => {
		try {
			uppy.removeFile(uf.id);
			const newUploads = uppy.getFiles();
			//setFiles(newUploads);

			setNumFiles(newUploads.length);
		} catch (error) {
			_logError(error);
		}
	}, []);
	/* eslint-enable */
	return (
		<>
			<Dashboard
				uppy={uppy}
				hideUploadButton={true}
				fileManagerSelectionType="both"
				proudlyDisplayPoweredByUppy={false}
			/>
		</>
	);
};

export default MultipleAssetPicker;
