import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { Link } from '@reach/router';
import { Avatar } from 'components/avatar.component';
import { Cell, ScrollCard, StageStatusPill } from 'components/index';
import { useWorkflowContext } from 'context/useWorkflowStore';
import * as R from 'ramda';
import React from 'react';
import { Table } from 'reactstrap';
import { useAuthContext, User } from 'utils/auth';
import { StageStatus } from 'utils/models/StageStatusModel';
import { buildStageUrl } from '../../../../../utils/common/links';
import { formatDuration } from '../../../../../utils/common';
import { flattenStages, getStageDueDate } from '../../helpers';
import {
	stageIsOverdue,
	useSubsequent,
} from '../../helpers/workflowStage.helpers';
import { Stage, Workflow } from '../../types';

interface Props {
	stages: Stage[];
	isFetching: boolean;
}

export const MyAssignmentsTable = ({ stages, isFetching }: Props) => {
	if (R.isEmpty(stages))
		return (
			<table>
				<tbody>
					<tr>
						<Cell className="pt-4">
							{isFetching
								? 'Loading Assignments...'
								: !stages?.length
								? 'No Results'
								: ''}
						</Cell>
					</tr>
				</tbody>
			</table>
		);

	return (
		<ScrollCard>
			<Table>
				<thead>
					<tr>
						<th>Stage Status</th>
						<th>Workflow</th>
						<th>Stage</th>
						<th>Expected Duration</th>
						<th>Due Date</th>
					</tr>
				</thead>
				<tbody>
					{stages &&
						stages.map((stage, index) => (
							<AssignmentTableRow key={`${index}_${stage._id}`} stage={stage} />
						))}
				</tbody>
			</Table>
		</ScrollCard>
	);
};

type AssignmentTableProps = {
	stage: Stage;
};
const AssignmentTableRow = ({ stage }: AssignmentTableProps) => {
	const { currentUser } = useAuthContext();
	const userIsProxying = (stage: Stage) => false;
	return (
		<tr key={stage._id}>
			<td>
				{' '}
				<StageStatusPill status={(stage.status as StageStatus) || '???'} />{' '}
				{stageIsOverdue(stage) && (
					<AccessTimeFilledIcon color="error" titleAccess="Overdue" />
				)}
			</td>
			{stage.workflowTitle && <td>{stage.workflowTitle}</td>}
			{userIsProxying(stage) && (
				<td className="h-100 p-0 m-0">
					<div className="mt-2 h-100 w-100 d-flex align-items-center justify-content-center">
						<Avatar user={currentUser?.proxyingFor as User} />
					</div>
				</td>
			)}
			<td className="mb-0">
				<div className="d-flex align-items-center justify-content-start">
					<Link className="ml-2" to={buildStageUrl(stage.instructions, stage)}>
						{stage.title}
					</Link>
				</div>
			</td>
			<td>
				{formatDuration(
					useSubsequent(stage)
						? stage?.expectedDurations?.subsequent || 0
						: stage?.expectedDurationHrs || 0
				)}
			</td>
			<td>
				{stage?.dueDate?.substring(0, 10) || getStageDueDate(stage) || 'TBD'}
			</td>
		</tr>
	);
};
