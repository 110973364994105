import { useReducer } from 'react';
import axios from 'axios';
import { useAuthContext, useHeaders } from '../utils/auth';
const fetchingReducer = (
	state: { isFetching: boolean },
	action: { type: 'set'; payload: boolean }
) => {
	switch (action.type) {
		case 'set':
			return { ...state, isFetching: action.payload };
		default:
			return state;
	}
};
type Defaults = {
	updatedAt: Date;
	createdAt: Date;
	updatedBy: string;
	createdBy: string;
};
export const useAxios = <T>(apiVerb: string, customRoute?: string) => {
	const { currentUser, updateState } = useAuthContext();
	const endpoint = `${process.env.REACT_APP_ROME_API_ENDPOINT}`;

	const [state, dispatch] = useReducer(fetchingReducer, {
		isFetching: false,
	});
	const { getHeaders } = useHeaders();
	const postCustom = async (id = '', body = {}) => {
		if (!customRoute)
			throw new Error('Cant get custom without a supplied custom route');
		const res = await axios.post(
			buildPath(`${customRoute}/${!!id ? id : ''}`),
			body,
			getHeaders()
		);
		return res.data;
	};
	const getCustom = async (id?: string) => {
		if (!customRoute)
			throw new Error('Cant get custom without a supplied custom route');
		const res = await axios.get<T>(
			buildPath(`${customRoute}/${!!id ? id : ''}`),
			getHeaders()
		);
		return res.data;
	};
	/**
	 * Avoid double slashes.
	 * @param path Path relative to the API root.
	 */
	const buildPath = (path: string, queryString: string = '') => {
		return !!customRoute
			? `${endpoint}/${path.replace(endpoint, '').replace(/^\//, '')}`
			: `${endpoint}/${apiVerb}/${path
					.replace(endpoint, '')
					.replace(/^\//, '')}${queryString ? `?${queryString}` : ''}`;
	};
	const updateOne = async (id: string, updated: Partial<T>,workflowId?:string) => {
		if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		const res = await axios
			.patch<T>(
				buildPath(!workflowId?`${id}`:`${id}/workflowId/${workflowId}`),
				{
					...updated,
					updatedAt: new Date(),
					updatedBy: currentUser?._id,
				},
				getHeaders()
			)
			.finally(() =>
				dispatch({
					type: 'set',
					payload: false,
				})
			);
		return res.data as T;
	};
	const createOne = async (created: T) => {
		//if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		const res = await axios
			.post<T>(
				buildPath(``),
				{
					...created,
				},
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));
		if (apiVerb === 'users') updateState(res.data as any);
		return res.data;
	};
	const findOne = async (id: string, workflowId?: string) => {
		// if (state.isFetching) return;
		// dispatch({ type: 'set', payload: true });

		if (!workflowId) {
			const res = await axios
				.get<T & Defaults>(buildPath(`${id}`), getHeaders())
				.finally(() => dispatch({ type: 'set', payload: false }));
			return res.data;
		} else {
			const res = await axios
				.get<T & Defaults>(
					buildPath(`${id}/workflow/${workflowId}`),
					getHeaders()
				)
				.finally(() => dispatch({ type: 'set', payload: false }));
			return res.data;
		}
	};
	const deleteOne = async (id: string) => {
		if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		const res = await axios
			.delete<T>(buildPath(`${id}`), getHeaders())
			.finally(() => dispatch({ type: 'set', payload: false }));
		return res.data;
	};
	const findAll = async (queryString: string = '') => {
		// if (state.isFetching) return;
		// dispatch({ type: 'set', payload: true });
		try {
			const res = await axios
				.get<Array<T & Defaults>>(buildPath(``, queryString), getHeaders())
				.finally(() => dispatch({ type: 'set', payload: false }));
			return res.data;
		} catch (e) {
			console.error(e);
			return [] as T[];
		}
	};
	return {
		updateOne,
		findOne,
		deleteOne,
		createOne,
		findAll,
		getCustom,
		postCustom,
	};
};
