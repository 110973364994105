import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useThemeContext } from 'context/useThemeContext';
import { useQueryStoreContext, QueryModel } from 'context/useQueryStore';
import React from 'react';
import { Button } from 'reactstrap';
import { NotificationsContext } from './notifications';
import { SecondaryButton } from './buttons.styled-components';

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
	children: React.ReactChild[] | React.ReactNode[] | any;
}
export const NewSecondaryButton: React.FC<
	ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, ...props }: any) => {
	const { defaults } = useThemeContext();
	return (
		<Button {...props} style={{ background: defaults?.secondary }}>
			{children}
		</Button>
	);
};

interface Props {
	className?: string;
	sort?: string;
	filter?: any;
	templates?: string;
	searchTerm: string;
	type: string;
}

export const SaveQueryButton = ({
	searchTerm,
	sort,
	filter,
	templates,
	type,
	className,
}: Props) => {
	const { info } = React.useContext(NotificationsContext);
	const queryStore = useQueryStoreContext();

	const href = window.location.href;
	const queryType = href.includes('workflows')
		? 'workflows'
		: href.includes('templates')
		? 'templates'
		: 'assignments';
	const entry = queryStore.entities.find((a) => a.type === queryType);

	const toggleQuery = () => {
		if (entry) {
			queryStore.remove(entry);
			info(`Cleared query`);
		} else {
			queryStore.addNew(
				searchTerm,
				sort ?? '',
				filter,
				templates ?? '',
				type,
				true
			);
			info(`Saved query`);
		}
	};

	return (
		<SecondaryButton
			className={`save-query-button ${className}`}
			onClick={toggleQuery}
			//disabled={searchTerm === ''}
		>
			<FontAwesomeIcon icon={faSave} className="mr-2" />
			{entry ? (
				<span className="button-label">Clear query</span>
			) : (
				<span className="button-label">Save query</span>
			)}
		</SecondaryButton>
	);
};

export default SaveQueryButton;
