import { faEdit, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { buildEmptyAssetMetadata } from 'components/dam-assets/components/helpers/useAssetHelper';
import RenderWhen from 'components/render-when.component';
import { useMetadataContext } from 'context/useMetadataContext';
import { useWorkflowContext } from 'context/useWorkflowStore';
import { isArray, map } from 'lodash';
import React, {
	ChangeEvent,
	RefObject,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	Col,
	Input,
	InputGroup,
	InputGroupAddon,
	Row,
	UncontrolledTooltip,
} from 'reactstrap';
import CircleButton from '../../circle-button.component';
import { LabeledInput } from '../../forms';
import { Select } from '../../forms/Select';
import { Option } from '../../forms/Select/Option/Option';
import { SelectedOption } from '../../forms/Select/select.helpers';
import TagInput from '../../tag-input/TagInput';
import { Subtitle } from '../../ui';
import {
	EntityMetadata,
	EntityMetadataTemplate,
} from '../../workflow/workflows/types/workflow.types';
import { EditFieldDialog } from './EditFieldValueDialog';
import { CircleButtonContainer } from './entity-metadata-form.styled-components';
import { useMetadataEditor } from './existing-metadata-fields.component';
import { useAxios } from 'hooks';
import { WorkflowTemplate } from '../../workflow/workflows/types/workflow.types';

const EntityMetadataForm = (props: {
	metadata: EntityMetadata;
	displayTemplateField?: boolean;
	update?: React.Dispatch<{ type: 'update'; payload: EntityMetadata }>;
	onChange?: (template?: EntityMetadataTemplate) => EntityMetadata;
	defaultSelect?: any;
	setDefaultSelect?: any;
}) => {
	const { metadata, displayTemplateField,defaultSelect,setDefaultSelect} = props;
	//const { allTemplates } = useWorkflowContext();
	const [editedMetadata, setEditedMetadata] = React.useState(metadata);
	const { setKvPair, setMeta, removeField, replaceField } = useMetadataEditor(
		metadata
	);
	const templateStore = useAxios<WorkflowTemplate>('templates');
	const [allTemplates, setTemplates] = React.useState<WorkflowTemplate[]>();
	const { templates } = useMetadataContext();
	const [newKvPair, setNewKvPair] = useState({ key: '', value: '' });
	const ref: RefObject<HTMLInputElement> = useRef(null);

	React.useEffect(() => {
		templateStore.findAll().then(setTemplates);
		//eslint-disable-next-line
	}, [window.location.hash]);

	useEffect(() => {
		if (window.location.hash) {
			const templateId = window.location.hash.replace('#', '');
			// @ts-ignore
			const templateMeta = allTemplates.find((m) => m._id === templateId)
				?.metadata;
			if (templateMeta) {
				setEditedMetadata(templateMeta);
				setMeta({ type: 'update', payload: templateMeta });
				if (props.onChange)
					props.onChange({
						title: '',
						...templateMeta,
					} as EntityMetadataTemplate);
				if (props.update)
					props.update({ type: 'update', payload: { ...templateMeta } });
			}
		}
		//	eslint-disable-next-line
	}, [window.location.hash]);

	const handleAddField = () => {
		const { key, value } = newKvPair;
		if (
			!!(
				key !== '' &&
				(!metadata.fields?.length || !metadata?.fields?.includes(key))
			)
		) {
			const updated = setKvPair(key, value);
			if (props.update) props.update({ type: 'update', payload: updated });
			if (props.onChange) props.onChange(updated as EntityMetadataTemplate);
			setEditedMetadata(updated);
			setMeta({ type: 'update', payload: updated });
			setNewKvPair({ key: '', value: '' });
		}
	};

	const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = target;
		setNewKvPair({ ...newKvPair, [name]: value });
	};

	const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			handleAddField();

			if (ref.current) {
				(event.target as HTMLInputElement).blur();
				ref.current.focus();
			}
		}
	};

	const renderMetadataTemplateSelect = () => {
		if (displayTemplateField !== undefined && !displayTemplateField) {
			return null;
		}

		return (
			<Col md={12} className="mt-3">
				<Select
					label="Metadata Template"
					name="metadata"
					search
					defaultValue={defaultSelect}
					onSelect={(selection: SelectedOption | SelectedOption[]) => {
						if(setDefaultSelect)setDefaultSelect('true');
						let selectedOpt: string = '';
						if (isArray(selection)) {
							if (!selection.length) {
								setMeta({ type: 'update', payload: buildEmptyAssetMetadata });
								setEditedMetadata(buildEmptyAssetMetadata);
								if (props.onChange) props.onChange();
								if (props.update)
									props.update({
										type: 'update',
										payload: buildEmptyAssetMetadata,
									});
								return;
							} else selectedOpt = selection[0]?.value as string;
						} else if (selection) selectedOpt = selection?.value as string;
						const selected = templates?.find(
							(temp: any) => temp._id === selectedOpt
						);
						setEditedMetadata({ ...selected, values: {} } as EntityMetadata);
						setMeta({ type: 'update', payload: selected as EntityMetadata });
						if (props.onChange) props.onChange(selected);
						if (props.update)
							props.update({
								type: 'update',
								payload: selected as EntityMetadata,
							});
					}}
				>
					{templates?.length ? (
						map(templates, (template: any) => (
							<Option
								label={template.title}
								value={template._id}
								key={template._id}
							></Option>
						))
					) : (
						<></>
					)}
				</Select>
			</Col>
		);
	};

	const inputRef = useRef<HTMLInputElement>();

	const handleRemoveField = (field: string) => {
		const updated = removeField(field);

		if (props.update) props.update({ type: 'update', payload: updated });
		if (props.onChange) props.onChange(updated as EntityMetadataTemplate);

		setEditedMetadata(updated);
		setMeta({ type: 'update', payload: updated });
	};

	const handleUpdateField = (newField: string, oldField: string) => {
		const updated = replaceField(oldField, newField);

		setEditedMetadata(updated);
		setMeta({ type: 'update', payload: updated });
		if (props.update) props.update({ type: 'update', payload: updated });
		if (props.onChange)
			props.onChange({ ...updated } as EntityMetadataTemplate);
	};

	const updateMetadataValue = (e: any, fieldKey: string) => {
		let updatedField;
		if (e.target.selectedOptions) {
			updatedField = Array.from(
				e.target.selectedOptions,
				(option: any) => option.value
			);
		} else updatedField = e.target.value;
		const edited = setKvPair(fieldKey, updatedField);
		setEditedMetadata(edited);
		setMeta({ type: 'update', payload: edited });
		if (props.update) props.update({ type: 'update', payload: edited });
	};

	function renderOptions(fieldKey: string) {
		const options: JSX.Element[] = [];
		if (
			editedMetadata?.fieldOptions !== undefined &&
			!!editedMetadata?.fieldOptions![fieldKey] &&
			!!(editedMetadata.fieldOptions as Record<
				string,
				{ field: string; options: string[] }
			>)[fieldKey]?.options
		) {
			(editedMetadata.fieldOptions as Record<
				string,
				{ field: string; options: string[] }
			>)[fieldKey]?.options?.map((opt) =>
				options.push(
					<option key={opt} value={opt}>
						{opt}
					</option>
				)
			);
		}
		return options;
	}
	const [editedField, setEditedField] = useState('');

	const getDefaultValue = (fieldKey: string) => {
		if (editedMetadata.values && editedMetadata.values[fieldKey]) {
			return editedMetadata.values[fieldKey];
		}
		return '';
	};

	/**
	 * Check if proposed key already exists in metadata
	 * @param key string
	 * @param metadata EntityMetadata
	 * @returns boolean
	 */
	const keyAlreadyExists = (key: string, metadata: EntityMetadata): boolean => {
		let key_exists = false;
		const fields = metadata.fields ? metadata.fields : [];
		const all_keys = fields.map((field: string) => field.toLowerCase());
		key_exists = all_keys.includes(key.toLowerCase());
		return key_exists;
	};

	/**
	 * Turn valid state value into a dynamic calulation
	 * @param key string
	 * @param metadata EntityMetadata
	 * @returns boolean
	 */

	const keyIsValid = (key: string, metadata: EntityMetadata): boolean => {
		if (key === '') return false;
		if (keyAlreadyExists(key, metadata)) return false;
		return true;
	};

	const [editingField, setEditingField] = useState(false);
	const render = () => (
		<>
			<Row>
				{renderMetadataTemplateSelect()}
				<Col md={12} className="mt-5 mb-1">
					<Subtitle>Metadata</Subtitle>
					<hr />
				</Col>
			</Row>
			{editingField && (
				<EditFieldDialog
					isOpen={editingField}
					oldValue={editedField}
					afterSubmit={(updatedField: string) => {
						handleUpdateField(updatedField, editedField);
						setEditingField(false);
						setEditedField('');
					}}
					onCancel={() => {
						setEditingField(false);
						setEditedField('');
					}}
				/>
			)}
			<>
				{editedMetadata.fields?.map((fieldKey: string) => {
					const fieldType = editedMetadata?.fieldTypes?.find(
						(ft) => ft.field === fieldKey
					)?.fieldType as string;
					return (
						<Row key={fieldKey} className="mb-3 mb-md-0">
							<Col md={4} xs={10}>
								<InputGroup>
									<input
										ref={(ref) => (inputRef.current = ref as HTMLInputElement)}
										className={'form-control'}
										readOnly
										type="text"
										name="key"
										defaultValue={fieldKey}
									/>

									<InputGroupAddon addonType="append">
										<UncontrolledTooltip target="editFieldTooltip">
											Edit metadata field
										</UncontrolledTooltip>
										<span
											className="input-group-text"
											onClick={() => {
												setEditedField(fieldKey);
												setEditingField(true);
											}}
										>
											<FontAwesomeIcon icon={faEdit} id="editFieldTooltip" />
										</span>
									</InputGroupAddon>
								</InputGroup>
							</Col>
							<Col md={5} xs={10}>
								<RenderWhen
									when={['singleSelect', 'multiSelect'].includes(fieldType)}
								>
									{'multiSelect' === fieldType ? (
										<select
											multiple
											className="form-control"
											name="value"
											value={
												Array.isArray(getDefaultValue(fieldKey))
													? getDefaultValue(fieldKey)
													: [...getDefaultValue(fieldKey)]
											}
											onChange={(e) => {
												updateMetadataValue(e, fieldKey);
											}}
										>
											<option key={''} value={''}>
												Please select...
											</option>
											{renderOptions(fieldKey)}
										</select>
									) : (
										<select
											className="form-control"
											name="value"
											value={
												Array.isArray(getDefaultValue(fieldKey))
													? getDefaultValue(fieldKey)[0]
													: getDefaultValue(fieldKey)
											}
											onChange={(e) => updateMetadataValue(e, fieldKey)}
										>
											<option key={''} value={''}>
												Please select...
											</option>
											{renderOptions(fieldKey)}
										</select>
									)}
								</RenderWhen>
								<RenderWhen when={'numeric' === fieldType}>
									<Input
										type={'number'}
										className="form-control"
										value={getDefaultValue(fieldKey)}
										onChange={(e) => updateMetadataValue(e, fieldKey)}
									/>
								</RenderWhen>
								<RenderWhen when={'date' === fieldType}>
									<Input
										type={'date'}
										placeholder="mm/dd/yyyy"
										pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
										className="form-control"
										value={getDefaultValue(fieldKey)}
										onChange={(e) => updateMetadataValue(e, fieldKey)}
									/>
								</RenderWhen>
								<RenderWhen
									when={'openType' === fieldType || fieldType === undefined}
								>
									<Input
										className="cy-wf-metadata-input"
										type="text"
										name="value"
										value={getDefaultValue(fieldKey)}
										onChange={(event) => {
											const edited = setKvPair(fieldKey, event.target.value);
											setEditedMetadata(edited);
											setMeta({ type: 'update', payload: edited });
											if (props.update)
												props.update({ type: 'update', payload: edited });
										}}
									/>
								</RenderWhen>
							</Col>
							<Col md={3} xs={2}>
								<CircleButton
									className="sm"
									color="danger"
									id="removeMetadataItem"
									icon={faMinus}
									onClick={() => handleRemoveField(fieldKey)}
									tooltip="Remove metadata entry"
								/>
							</Col>
						</Row>
					);
				})}
				<hr />
			</>

			<Row>
				<Col md={12} className="mb-0 mt-3">
					<h2 className="mb-1">Add New Metadata</h2>
					<p>Fill the inputs and click the add button to save.</p>
				</Col>
			</Row>
			<Row>
				<Col md={4} xs={10}>
					<LabeledInput
						label="Field name"
						type="text"
						name="key"
						id="metadataKey"
						value={newKvPair.key}
						onChange={handleInputChange}
						onKeyDown={handleInputKeyDown}
						innerRef={ref}
						inputValid={keyIsValid(newKvPair.key, editedMetadata)}
						errorMsg={
							keyAlreadyExists(newKvPair.key, editedMetadata)
								? `A field named ${newKvPair.key} was already added. Please choose another name.`
								: newKvPair.key === ''
								? 'This field cannot be empty.'
								: ''
						}
					/>
				</Col>
				<Col md={5} xs={10}>
					<LabeledInput
						label="Field value"
						type="text"
						name="value"
						id="metadataValue"
						value={newKvPair.value}
						onChange={handleInputChange}
						onKeyDown={handleInputKeyDown}
					/>
				</Col>
				<Col md={3} xs={2}>
					<CircleButtonContainer>
						<CircleButton
							id="addMetadataItem"
							className="sm cy-wf-add-metadata-button"
							icon={faPlus}
							onClick={handleAddField}
							tooltip="Add metadata entry"
						/>
					</CircleButtonContainer>
				</Col>
			</Row>

			<Row>
				<Col md={12} className="mb-0 mt-3">
					<h2 className="mb-1">Tags</h2>

					<TagInput
						label="Manage tags for this workflow below."
						onChange={(updated: EntityMetadata) => {
							setEditedMetadata(updated as EntityMetadata);
							setMeta({ type: 'update', payload: updated as EntityMetadata });
							if (props.update)
								props.update({ type: 'update', payload: updated });
						}}
						metadata={metadata}
					/>
				</Col>
			</Row>
		</>
	);

	return render();
};

export default EntityMetadataForm;
