import React from 'react';
import './LoadingIndicator.scss';
import classNames from 'classnames';

interface LoadingIndicatorProps {
	dismiss: boolean;
}

export default function LoadingIndicator({ dismiss }: LoadingIndicatorProps) {
	return (
		<div
			className={classNames('no-freeze-spinner', { dismiss })}
			style={{ display: dismiss ? 'none' : '' }}
		>
			<div id="no-freeze-spinner">
				<div>
					<i className="material-icons">account_tree</i>
					<i className="material-icons">photo_library</i>
					<i className="material-icons">description</i>
					<i className="material-icons">account_circle</i>
					<div></div>
				</div>
			</div>
		</div>
	);
}
