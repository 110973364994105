import { Stage } from 'components/workflow/workflows/types';
import React, { useState, useEffect } from 'react';
import { useAuthContext, User } from '../../../../utils/auth';
import { MyAssignmentsTable } from '../components';
import { WorkflowsIndexView } from 'components/workflow/workflows/WorkflowIndexPage';
import { useWorkflowContext } from 'context/useWorkflowStore';
import { useQueryStoreContext } from 'context/useQueryStore';

type Filter =
	| 'all'
	| 'active'
	| 'pipeline'
	| 'completed'
	| 'overdue'
	| 'roadblocked';

/**
 * Creates table of assignments for current user
 * @returns WorkflowsIndexView
 */
export const MyAssignments = () => {
	const queryStore = useQueryStoreContext();
	const workflowQuery = queryStore.entities.find(
		(a) => a.type === 'assignments'
	);

	const {
		stages,
		stagesSearchTerm,
		stagesTotalDocs,
		template,
		fetchAllStages,
		isFetching,
		stagesTotalPages:stagesTotalPages
	} = useWorkflowContext();

	const { currentUser } = useAuthContext();
	const [searchTermState, setSearchTermState] = useState(workflowQuery?.value??'');
	const [stagesCurrentPage, setStagesCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(stagesTotalPages);
	const [sort, setSort] = useState<string>(workflowQuery?.sort ?? '');
	const [defaultFilter, setDefaultFilter] = useState(
		(workflowQuery?.filters) ?? []
	);

	useEffect(() => {
		setTotalPages(stagesTotalPages);
	}, [stagesTotalDocs]);

	useEffect(() => {
		fetchAllStages(
			stagesCurrentPage,
			defaultFilter,
			sort,
			template,
			searchTermState
		);
	}, [
		defaultFilter,
		stagesCurrentPage,
		stagesSearchTerm,
		template,
		searchTermState,
		sort,
	]);

	const sortingOptions = [
		'A - Z',
		'Z - A',
		'Newest - Oldest',
		'Oldest - Newest',
	].map((val) => ({
		label: val,
		value: val,
	}));

	const filterOptions = [
		currentUser?.proxyingFor?._id ? ['Proxied Assignments', 'proxied'] : [],
		['All Assignments', 'all'],
		['Active', 'active'],
		['Pipeline', 'queue'],
		['Completed', 'completed'],
		['Overdue', 'overdue'],
		['Roadblocked', 'roadblocked'],
	]
		.filter(([label, value]) => !!label && !!value)
		.map(([label, value]) => ({ label, value }));

	// * These are passed to the WorkflowsIndexView.
	// * Don't need them if not providing dynamic sorting and filtering
	// * Basically, we're bothering with the complex view output because it paginates
	const views = {
		table: (stages: Stage[]) => (
			<MyAssignmentsTable stages={stages} isFetching={isFetching} />
		),
	};

	const handleFilter = (value: string | string[]) => {
		const filters = Array.isArray(value) ? value : [value];
		setDefaultFilter(filters);
	};

	const handleSort = (sorter: string) => {
		setSort(sorter);
	};

	const sortingConfig = {
		options: sortingOptions,
		fn: handleSort,
	};
	const filtersConfig = {
		options: filterOptions,
		fn: handleFilter,
	};

	return (
		<>
			<WorkflowsIndexView
				defaultFilter={defaultFilter}
				defaultSort={sort}
				title="My Assignments"
				collection={stages}
				// ! passing functions here
				sorting={sortingConfig}
				filters={filtersConfig}
				views={views}
				searchTarget="workflow"
				isFetching={isFetching}
				searchTerm={searchTermState}
				setSearchTerm={setSearchTermState}
				currentPage={stagesCurrentPage}
				setCurrentPage={setStagesCurrentPage}
				totalPages={totalPages}
				totalDocs={stagesTotalDocs}
			/>
		</>
	);
};
