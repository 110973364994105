import {
	faCheckCircle,
	faChevronDown,
	faChevronRight,
	faEllipsisV,
	faTasks,
	faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Subheading } from '../../../../ui';
import * as R from 'ramda';
import {
	ApproveStageButton,
	BypassStageButton,
	ForceActivateButton,
	RejectStageButton,
} from '../stage-actions';
import { IconWithRightMargin } from '../action-button.styled-components';
import { Timeline } from '../timeline/timeline.component';
import ActivateFirstStageButton from '../stage-actions/activate-first-stage-button.component';
import CommentForm from '../comment-form.component';
import {
	DetailCard,
	DetailCardBody,
} from './workflow-stage-card.styled-components';
import StageCardDetails from './workflow-stage-card-details.component';
import StageInputSlots from './workflow-stage-input-slots.component';
import StageInstructions from './workflow-stage-instructions.component';
import { RoleContext } from '../../../../../context/PermissionsContext';
import { StatusEnum } from '../../types/workflowStatus.types';
import {
	Stage,
	StageEvent,
	StageTask,
	Workflow,
	WorkflowTemplate,
} from '../../types/workflow.types';
import { useWorkflowContext } from 'context/useWorkflowStore';
import { useGroupContext, useAuthContext } from 'utils/auth';
import {
	isActionableStage,
	userIsOwner,
} from 'components/workflow/workflows/helpers';
import { StageDetailCardHeader } from './StageDetailsCardHeader';
import { RevertStageButton } from './RevertStageButton';
import {
	CardFooter,
	DropdownMenu,
	DropdownToggle,
	Input,
	Label,
	ListGroup,
	ListGroupItem,
	ListGroupItemHeading,
	ListGroupItemText,
	UncontrolledDropdown,
	UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropDownMenuItem } from 'components/drop-down-menu.component';
import { StyledLink } from 'components/dam-assets/components/asset-card.styled-components';
import { PaddedBadge } from 'components/dashboard.component/components/workflow-health-flag.component';
import moment from 'moment';
import RenderWhen from 'components/render-when.component';
interface StageDetailCardProps {
	id: string;
}
const StageDetailCard = React.memo((props: StageDetailCardProps) => {
	const { stage, workflow, updateStageTask } = useWorkflowContext();
	const { canViewByRole } = React.useContext(RoleContext);
	const { currentUser } = useAuthContext();
	const canForceActivateStage = (stage: Stage) => {
		return (
			(stage &&
				stage.owners &&
				stage.owners.some(
					(m) =>
						m._id === currentUser?.proxyingFor?._id || m._id === currentUser._id
				)) ||
			currentUser.isAdmin
		);
	};
	const renderForceActivateButton = () => {
		if (
			stage?.status !== StatusEnum.completed &&
			canForceActivateStage(stage as Stage) &&
			canViewByRole('wfEditable')
		) {
			if (
				workflow?.stages &&
				R.equals(R.head(workflow?.stages), stage) &&
				stage?.type !== 'parallel'
			) {
				return <ActivateFirstStageButton />;
			} else if (stage?.status === StatusEnum.pipeline) {
				return <ForceActivateButton stage={stage} />;
			}
			return null;
		} else {
			if (stage?.status === StatusEnum.completed && stage?.type !== 'sideTask')
				return (
					<RevertStageButton workflow={workflow as Workflow} stage={stage} />
				);
			return null;
		}
	};
	const { groupsForCurrentUser } = useGroupContext();
	const [views, setViews] = React.useState([
		{
			label: 'View Comments',
			predicate: (event: StageEvent) =>
				['comment'].some((type) => event.type === type && !event.disabled),
			isToggled: true,
		},
		{
			label: 'View Timeline',
			predicate: (event: StageEvent) =>
				['statusChange', 'assetUpload', 'backwardTransition', 'setOwner'].some(
					(type) => event.type === type
				),
			isToggled: true,
		},
		{
			label: 'View Deleted Comments',
			predicate: (event: StageEvent) =>
				!!event.disabled && event.type === 'comment',
			isToggled: false,
		},
	]);

	const [tabViews, setTabViews] = React.useState({
		details: true,
		tasks: false,
	});

	const filterForAccessLogs = React.useMemo(() => {
		if (!stage?.events) return [];

		const allPredicates = views
			.filter((a) => a.isToggled)
			.map((a) => a.predicate);
		return stage.events.filter((event) =>
			allPredicates.some((predicate) => !!predicate(event))
		);
	}, [views, stage]);

	const updateForTask = async (task: StageTask, isComplete: boolean) => {
		if (stage && workflow) {
			await updateStageTask(
				task._id,
				workflow?._id as string,
				stage?._id as string,
				isComplete
			);
		}
	};

	if (!workflow || !stage) return null;

	return (
		<DetailCard>
			<StageDetailCardHeader workflow={workflow} stage={stage} />

			<DetailCardBody>
				<div className="d-flex mb-4 justify-content-between">
					<h2 className="align-self-center mb-0">
						{stage.status === 'completed' ? (
							<IconWithRightMargin icon={faCheckCircle} className="completed" />
						) : null}
						<span className="d-inline-block">
							<Subheading>
								{stage.phase &&
								(workflow?.templateUsed as WorkflowTemplate)?.phases?.some(
									(phase) => phase._id === stage.phase
								)
									? (workflow?.templateUsed as WorkflowTemplate)?.phases?.find(
											(phase) => phase._id === stage.phase
									  )?.title
									: null}
							</Subheading>
							{stage.title}
						</span>
					</h2>

					<div className="d-flex">
						{isActionableStage(stage, currentUser, groupsForCurrentUser) ? (
							<>
								<ApproveStageButton workflow={workflow} stage={stage} />
								<RejectStageButton workflow={workflow} stage={stage} />
								<BypassStageButton
									workflow={workflow}
									stage={stage}
									className="reverse"
								/>
							</>
						) : null}
						{renderForceActivateButton()}
					</div>

					<div className="d-flex justify-content-end">
						<UncontrolledTooltip target={'workflow-event-toggler'}>
							Toggle different stage events based on event type
						</UncontrolledTooltip>
						<UncontrolledDropdown id={'workflow-event-toggler'}>
							<DropdownToggle color="white">
								<FontAwesomeIcon icon={faEllipsisV} />
							</DropdownToggle>
							<DropdownMenu>
								<DropDownMenuItem
									onClick={() => {
										const isToggled = !views.find(
											(view) => view.label === 'View Comments'
										)!.isToggled;
										setViews((views) =>
											views.map((v) =>
												v.label === 'View Comments'
													? { ...v, isToggled }
													: { ...v }
											)
										);
									}}
								>
									<Label check className="pl-2">
										<Input
											checked={views.some(
												(a) => a.label === 'View Comments' && a.isToggled
											)}
											type={'checkbox'}
											onChange={() => {}}
										/>
										View Comments
									</Label>
								</DropDownMenuItem>
								<DropDownMenuItem
									onClick={() => {
										const isToggled = !views.find(
											(view) => view.label === 'View Deleted Comments'
										)!.isToggled;
										setViews((views) =>
											views.map((v) =>
												v.label === 'View Deleted Comments'
													? { ...v, isToggled }
													: { ...v }
											)
										);
									}}
								>
									<Label check className="pl-2">
										<Input
											checked={views.some(
												(a) =>
													a.label === 'View Deleted Comments' && a.isToggled
											)}
											type={'checkbox'}
											onChange={() => {}}
										/>
										View Deleted Comments
									</Label>
								</DropDownMenuItem>
								<DropDownMenuItem
									onClick={() => {
										const isToggled = !views.find(
											(view) => view.label === 'View Timeline'
										)!.isToggled;
										setViews((views) =>
											views.map((v) =>
												v.label === 'View Timeline'
													? { ...v, isToggled }
													: { ...v }
											)
										);
									}}
								>
									<Label check className="pl-2">
										<Input
											checked={views.some(
												(a) => a.label === 'View Timeline' && a.isToggled
											)}
											type={'checkbox'}
											onChange={() => {}}
										/>
										View Timeline
									</Label>
								</DropDownMenuItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</div>
				</div>
				{
					<StageCardDetails stage={stage}>
						<>
							<StageInstructions stage={stage} />
							<StageInputSlots workflow={workflow} id={props.id} />

							<div className="my-4">
								<Timeline
									showDelete
									workflow={workflow}
									events={filterForAccessLogs}
								/>
							</div>
							<CommentForm stage={stage} />
						</>
					</StageCardDetails>
				}
			</DetailCardBody>
			{userIsOwner(stage, currentUser, groupsForCurrentUser) && (
				<CardFooter className="bg-white">
					{stage && stage.tasks && stage?.tasks?.length > 0 && (
						<React.Fragment>
							<StyledLink
								to="#"
								onClick={() =>
									setTabViews({ details: false, tasks: !tabViews.tasks })
								}
							>
								Toggle Stage Tasks <FontAwesomeIcon icon={faTasks} />
							</StyledLink>
							<FontAwesomeIcon
								className="pl-1"
								icon={tabViews.tasks ? faChevronDown : faChevronRight}
							/>
						</React.Fragment>
					)}
					{tabViews.tasks && (
						<StageCardDetails stage={stage}>
							<ListGroup color="secondary" flush inputMode="text">
								{stage?.tasks?.map((task, index) => (
									<ListGroupItem
										key={'stage_' + stage._id + 'task_' + task._id}
									>
										<ListGroupItemHeading>
											{task.label}{' '}
											<RenderWhen
												when={
													stage.status === 'completed' ||
													workflow.status === 'completed'
												}
											>
												<UncontrolledTooltip
													target={
														'tip_' +
														index +
														'stage_' +
														stage._id +
														'task_' +
														task._id
													}
												>
													Cannot update stage tasks after{' '}
													{stage.status === 'completed'
														? 'a stage'
														: 'the workflow'}{' '}
													has been completed.
												</UncontrolledTooltip>
												<FontAwesomeIcon
													color={
														task.events &&
														task?.events?.length &&
														task?.events?.length > 0 &&
														task.events[task.events.length - 1].isCompleted
															? 'green'
															: 'red'
													}
													id={
														'tip_' +
														index +
														'stage_' +
														stage._id +
														'task_' +
														task._id
													}
													icon={
														task.events &&
														task?.events?.length &&
														task?.events?.length > 0 &&
														task.events[task.events.length - 1].isCompleted
															? faCheckCircle
															: faTimesCircle
													}
												/>
											</RenderWhen>
											<RenderWhen
												when={
													stage.status !== 'completed' &&stage.status !== 'queue' && 
													workflow.status !== 'completed'
												}
											>
												<Input
													className="ml-3"
													type={'checkbox'}
													style={{
														pointerEvents:
															stage.status === 'completed' ||
															workflow.status === 'completed'
																? 'none'
																: 'auto',
													}}
													checked={
														task.events &&
														task?.events?.length &&
														task?.events?.length > 0
															? task.events[task.events.length - 1].isCompleted
															: false
													}
													onChange={(e) =>
														updateForTask(task, e.target.checked)
													}
												/>
											</RenderWhen>
										</ListGroupItemHeading>
										<ListGroupItemText>
											<PaddedBadge
												color={
													task.events &&
													task?.events?.length &&
													task?.events?.length > 0 &&
													task.events[task.events.length - 1].isCompleted
														? 'success'
														: stage.status !== 'completed' &&
														  (!task?.events || task?.events.length === 0)
														? 'warning'
														: 'danger'
												}
											>
												{task.events &&
												task?.events?.length &&
												task?.events?.length > 0 &&
												task.events[task.events.length - 1].isCompleted
													? `Marked completed by ${
															task.events[task.events.length - 1].createdBy?.givenName
													  } ${
															task.events[task.events.length - 1].createdBy?.familyName
													  } at `
													: task.events &&
													  task?.events?.length &&
													  task?.events?.length > 0 &&
													  !task.events[task.events.length - 1].isCompleted
													? `Marked incomplete by ${
															task.events[task.events.length - 1].createdBy?.givenName
													  } ${
															task.events[task.events.length - 1].createdBy?.familyName
													  } at `
													: stage?.status === 'completed'
													? 'Not Completed'
													: 'TO DO'}
												{task.events &&
												task?.events?.length &&
												task?.events?.length > 0
													? moment(
															task.events[task.events.length - 1].createdAt
													  )
															.toDate()
															.toLocaleString()
													: ''}
											</PaddedBadge>
										</ListGroupItemText>
									</ListGroupItem>
								))}
							</ListGroup>
						</StageCardDetails>
					)}
				</CardFooter>
			)}
		</DetailCard>
	);
});

export default StageDetailCard;
