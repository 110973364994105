import themeStore from 'core-ui/models/ThemeStore';
import { startCase } from 'lodash';
import React from 'react';
import { Badge } from 'reactstrap';
import styled from 'styled-components';
import { buildClassList } from 'utils/common';
import storageModel from 'utils/models/StorageModel';
import { SiteDefaults } from '../context/useThemeContext';

const { colors } = themeStore.selectedTheme;
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
const Pill = styled(Badge)`
	color: var(--white);

	&& {
		padding: 4px 6px;
		font-size: 11px;
	}
	&.badge-pipeline {
		background: ${defaults?.pipeline};
	}
	&.badge-active {
		background-color: ${colors.active};
	}
	&.badge-overdue {
		background-color: ${colors.overdue};
	}
	&.badge-blocked {
		background-color: ${colors.blocked};
	}
	&.badge-cancelled {
		background-color: red;
	}
	&.badge-completed {
		background-color: ${colors.success};
	}
`;

export const workflowStatusColors: Record<string, string> = {
	pipeline: 'pipeline',
	active: 'active',
	paused: 'blocked',
	cancelled: 'cancelled',
	completed: 'completed',
	queue: 'pipeline',
};

export const WorkflowStatusPill = (props: {
	status: string;
	className?: string;
}) => {
	const mappedStatus = workflowStatusColors[props.status];
	const render = () => (
		<Pill
			pill
			className={buildClassList('workflow-status-pill', props.className)}
			// @ts-ignore TODO: change to tuple type
			color={workflowStatusColors[mappedStatus]}
		>
			{startCase(mappedStatus)}
		</Pill>
	);

	return render();
};

export default WorkflowStatusPill;
