import { UserGroup } from 'components/accounts/types';
import { isLastStageInList } from 'components/workflow/workflow-templates/components/stage-cards/Stage.helpers';
import { StageType } from 'components/workflow/workflow-templates/models';
import { orderBy, values } from 'lodash';
import moment from 'moment';
import * as R from 'ramda';
import { Maybe } from 'types/globals';
import { User } from 'utils/auth';
import * as T from '../types/workflow.types';
import {
	Stage,
	StageEvent,
	Workflow,
	WorkflowTemplate,
} from '../types/workflow.types';
import { StatusEnum } from '../types/workflowStatus.types';

// this function exists inside of the common file, but using it causes mobx error in teh test file
// thus, I just copied and pasted it here.  mobx sucks.
export function generateID() {
	const hex = (x: number) => (~~x).toString(16);
	const randomByte = () => hex(Math.random() * 16);
	return `${hex(Date.now() / 1000)}${' '.repeat(16).replace(/./g, randomByte)}`;
}

const getRevertedStageIndex = (stageToRevert: T.Stage, stages: T.Stage[]) =>
	stages.reduce(
		(accum, stage, index) =>
			stage.title === stageToRevert.title ? index : accum,
		0
	);

/**
 * Determine whether stage is last in chain
 * @param stage Stage
 * @param stages Stage[]
 * @returns boolean
 */
export const isFinalStage = (stage: T.Stage, stages: T.Stage[]) => {
	if (stage.substages) return false;
	if (R.not(isLastStageInList(stage, stages))) return false;
	return true;
};

export const isInitialStage = (stage: T.Stage, stages: T.Stage[]) =>
	R.equals(R.head(stages), stage);

export const recursiveFlattenStages = (stages: T.Stage[]): T.Stage[] => {
	if (R.not(R.any(R.has('substages'), stages))) return stages;

	const flattenedStages = R.flatten(
		stages.map((stage) => {
			if (!stage.substages) return stage;
			return [R.omit(['substages'], stage), ...R.flatten(stage.substages)];
		})
	);

	return recursiveFlattenStages(flattenedStages);
};

/**
 * Returns the next stage or undefined if there is no next stage
 * @param stage previous stage
 * @param stages all stages on template
 */
export const getNextStage = (
	stage: T.Stage | undefined,
	stages: T.Stage[]
): T.Stage | undefined => {
	if (!stage) return undefined;
	// if last stage in stages list, return null
	if (R.equals(stage, R.last(stages))) return undefined;

	if (R.includes(stage, stages)) return stages[R.indexOf(stage, stages) + 1];

	// @ts-ignore test pass. I'm on a deadline.  fix this later. blame management.
	return stages.reduce((nextStage, { substages }) => {
		if (!substages) return undefined;
		if (nextStage) return nextStage;
		return substages.reduce(
			// @ts-ignore test pass. I'm on a deadline.  fix this later. blame management.
			(_, substage) => getNextStage(stage, substage),
			undefined
		);
	}, undefined);
};

export const _getNextStage = (
	stage: T.Stage,
	workflow: T.Workflow
): T.Stage | undefined => {
	if (!workflow) return;
	// const nextStageId = stage.transitions?.find(isForward)?.targetStage;
	const nextStageId = '';
	return workflow?.stages?.find((wfStage) => wfStage._id === nextStageId);
};

export const emptyMetadataTemplate = (): T.EntityMetadataTemplate => {
	return {
		title: '',
		_id: generateID(),
		fields: [''],
		fieldTypes: [{}],
		fieldOptions: {},
		values: {},
		tags: [''],
	} as T.EntityMetadataTemplate;
};

/**
 * Returns flat array of stages and substages in workflow and optionally side tasks
 * @param object Flattenable
 * @param includeSideTasks boolean
 * @returns Stage[]
 */
export const flattenStages = (
	object: T.Flattenable,
	includeSideTasks = false
) => {
	const flat = [
		...(object?.stages || [])?.flatMap((stage: T.Stage) => {
			if (stage?.substages?.length) {
				return [stage, ...stage.substages.flatMap((s) => s)];
			}
			return stage;
		}),
		...(!!includeSideTasks ? object?.sideTasks || [] : []),
	];
	return flat;
};

/**
 * Return array of all stages in a workflow
 * @param workflows
 * @returns Stage[]
 */
export const getAllStages = (workflows: T.Workflow[]) => {
	const allStages: T.Stage[] = [];

	workflows?.forEach((workflow) => {
		const allWorkflowStages = flattenStages(workflow as T.Flattenable, true);
		allWorkflowStages.forEach((stage) => {
			stage.workflowTitle = workflow.title;
			stage.instructions = workflow._id;
		});
		allStages.push(...(allWorkflowStages || []));
	});

	return allStages;
};

/**
 * Type-safe check for substages in s stage
 * @param stage Stage
 * @returns boolean
 */
export const hasSubstages = (stage: Stage) => {
	return stage.substages !== undefined && stage.substages?.length > 0;
};

/**
 * Does stage have a backward transition? returns boolean
 * @param stage Stage
 * @returns boolean
 */
export const hasBackwardTransition = (stage: T.Stage): boolean => {
	if (!stage.transitions) return false;
	return stage.transitions.some((transition) => transition.type === 'backward');
};

/**
 * Return the parent stage of stage
 * @param workflow Workflow | WorkflowTemplate
 * @param stage Stage
 * @returns Stage
 */
//@ts-ignore
export const getStageParent = (
	workflow: T.Workflow | T.WorkflowTemplate,
	stage: T.Stage
): Stage | undefined => {
	if (!workflow || !stage) {
		return undefined;
	}
	// flatten and filter out substages and other stages with no substages
	const parent_stages = flattenStages(workflow as T.Flattenable, false)
		.filter(
			(stage) => stage.type !== StageType.substage && stage.substages?.length
		)
		.map((stage) => {
			return { _id: stage._id, title: stage.title, substages: stage.substages };
		});

	// find the stage in SUBSTAGES
	for (let i = 0; i < parent_stages.length; i++) {
		if (parent_stages[i].substages?.flat().find((s) => s._id === stage._id)) {
			//@ts-ignore
			return parent_stages[i];
		}
	}

	// no parent stage found. return false
	return undefined;
};

/**
 * convenience function to return a boolean value indicating whether stage is a substage
 * @param workflow Workflow | WorkflowTemplate
 * @param stage Stage
 * @returns boolean
 */
export const isSubstage = (
	workflow: T.Workflow | T.WorkflowTemplate,
	stage: Stage
): boolean => {
	const parent_stage = getStageParent(workflow, stage);
	if (parent_stage) {
		return true;
	}
	return false;
};

/**
 * convenience function to return a boolean value indicating whether stage is a parent stage
 * @param stage Stage
 * @returns boolean
 */
export const isParentStage = (stage: Stage): boolean => {
	return stage.substages !== undefined;
};

/**
 * Get the label for a stage
 * @param status
 * @returns string
 */
const getStageLabel = (status: StatusEnum) => {
	return status === StatusEnum.queue ? `Pipeline` : status;
};

/**
 * Check if all stages of workflow or
 * template have assigned owners
 * @param workflow Workflow | WorkflowTemplate
 * @returns boolean
 */

export const allStagesHaveOwner = (
	workflow: Workflow | WorkflowTemplate
): boolean => {
	const stages = flattenStages(workflow as T.Flattenable);

	if (!stages) {
		return false;
	}

	// filter out parallel stages
	const filtered_stages = stages.filter((stage) => {
		return stage.type !== 'parallel';
	});

	const has_stages_without_owner: boolean = filtered_stages
		?.filter((s) => s.type !== 'parallel')
		.every(
			(stage) =>
				!!stage.owners && !!stage.owners.length && stage.owners.length > 0
		);

	return has_stages_without_owner;
};

/**
 * Get list of stage owners as string
 * @param owners
 * @returns string
 */
const ownerList = (owners: (User | UserGroup)[]) => {
	return owners
		?.map((owner: User | UserGroup) =>
			(owner as UserGroup)?.BrandPermissions
				? owner.title
				: `${(owner as User)?.givenName} ${(owner as User)?.familyName}`
		)
		.join(', ')
		.replace(/, ([^,]*)$/, '');
};

export const getActiveStage = (workflow: T.Workflow) => {
	// if workflow completed, return initial stage
	if (workflow.status === 'completed') return getInitialStage(workflow);

	// return first stage whose status is 'active' or 'roadblocked'
	const activeStage = recursiveFlattenStages(workflow.stages).find(
		({ status }) => status === 'active' || status === 'roadblocked'
	);

	// if no active stage, return first stage
	return activeStage ?? getInitialStage(workflow);
};

export const getInitialStage = (workflow: T.Workflow) => {
	return workflow.stages[0];
};

export const useSubsequent = (stage: T.Stage): boolean => {
	if (!stage.events) return false;
	const statusChangeEvents = stage.events.filter(
		(m: any) => m.type === 'statusChange'
	) as StageEvent[];
	const activeChangeEvents = statusChangeEvents.filter(
		(m) => m.newStatus === 'active'
	);
	if (!activeChangeEvents.length) return false;

	// check if the stage has been completed, and is active indicating a rejection occurred.
	if (
		activeChangeEvents.length > 0 &&
		stage.events?.filter(
			(a) => a.newStatus === 'completed' || a.newStatus === 'queue'
		).length > 0
	) {
		// if stage was rejected, and has subsequent expected duration, use that.
		if (stage.expectedDurations?.subsequent) return true;
	}

	return false;
};

export const getStageDueDate = (
	stage: T.Stage,
	useSubequentCalc: boolean = true
) => {
	if (!stage.events) return '';
	const statusChangeEvents = stage.events.filter(
		(m: any) => m.type === 'statusChange'
	) as StageEvent[];
	const activeChangeEvents = statusChangeEvents.filter(
		(m) => m.newStatus === 'active'
	);
	if (!activeChangeEvents.length) return 'TBD';

	// check if the stage has been completed, and is active indicating a rejection occurred.
	if (
		useSubequentCalc &&
		activeChangeEvents.length > 0 &&
		stage.events?.filter(
			(a) => a.newStatus === 'completed' || a.newStatus === 'queue'
		).length > 0
	) {
		// if stage was rejected, and has subsequent expected duration, use that.
		if (stage.expectedDurations?.subsequent)
			return moment(
				orderBy(activeChangeEvents, (a) => a.createdAt, 'desc')[0].createdAt
			)
				.add(stage.expectedDurations?.subsequent, 'hours')
				.toISOString()
				.substring(0, 10);
	}

	const date = moment(
		orderBy(activeChangeEvents, (a) => a.createdAt, 'desc')[0].createdAt
	)
		.add(stage.expectedDurationHrs, 'hours')
		.toISOString();

	return date.substring(0, 10);
};

/**
 * Returns whether stage is overdue
 * @param stage : Stage
 * @returns boolean
 */
export const stageIsOverdue = (stage: Stage) => {
	// only consider active stages
	if (stage.status !== 'active') {
		return false;
	}
	const now = moment();
	if (
		getStageDueDate(stage as Stage) &&
		moment(getStageDueDate(stage as Stage)).isBefore(now)
	)
		return true;
	return false;
};

/**
 * return a stage event as a string
 * @param stageEvent StageEvent
 * @param removed (User | UserGroup)[]
 * @param added (User | UserGroup)[]
 * @returns string
 */
export const getStageEventAsString = (
	stageEvent: StageEvent,
	removed: (User | UserGroup)[],
	added: (User | UserGroup)[]
) => {
	let addedMsg = '';
	let removedMsg = '';
	let connector = '';

	if (!stageEvent?.type) return '';

	if (stageEvent.type === 'statusChange')
		return `changed the status from ${getStageLabel(
			stageEvent.oldStatus as StatusEnum
		)} to ${getStageLabel(stageEvent.newStatus as StatusEnum)}.`;

	if ((added || []).length) {
		addedMsg = `assigned stage to ${ownerList(added || [])}`;
	}

	if (values(removed || []).length) {
		removedMsg = `unassigned stage from ${ownerList(removed || [])}`;
	}

	if (values(added || []).length && values(removed || []).length) {
		connector = 'and';
	}

	return `${addedMsg} ${connector} ${removedMsg}`;
};

export const revertStage = (stage: T.Stage) => {
	return { ...stage, status: 'pipeline' };
};

const revertStages = (
	stages: T.Stage[],
	resetStageIndex: number,
	shouldRevert?: boolean
) =>
	stages.map((stage, index) => {
		const isRevertedStage = index <= resetStageIndex || shouldRevert;

		if (!isRevertedStage) return stage;
		if (stage.substages)
			revertStages(
				stage.substages.flatMap((s) => s),
				resetStageIndex,
				isRevertedStage
			);

		return {
			...stage,
			status: 'pipeline',
		};
	});

const isUserOwnerOrFollower = (
	template: T.WorkflowTemplate,
	currentUser: User,
	groupsForCurrentUser: UserGroup[]
) => {
	if (!template) return false;
	const isFollower =
		!!template.followers?.some((follower) => {
			return (
				currentUser?.proxyingFor?._id === follower?._id ||
				follower?._id === currentUser?._id ||
				!!groupsForCurrentUser?.some(
					(group: UserGroup) => group?._id === follower?._id
				)
			);
		}) ||
		!!((template?.createdBy as User)?._id === currentUser?._id) ||
		!!template?.owners?.some(
			(owner) =>
				currentUser?.proxyingFor?._id === owner?._id ||
				owner?._id === currentUser?._id ||
				groupsForCurrentUser?.some((group) => group._id === owner._id)
		);

	const isStakeholder = flattenStages(
		template as T.Flattenable
	)?.some((stage) =>
		stage?.owners?.some(
			(owner) =>
				currentUser?.proxyingFor?._id === owner?._id ||
				owner?._id === currentUser?._id ||
				groupsForCurrentUser?.some((grp) => grp._id === owner._id)
		)
	);
	return isFollower || isStakeholder;
};

export const canView = (
	workflow: T.Workflow,
	currentUser: User,
	groupsForCurrentUser: UserGroup[]
) => {
	const isStakeholder = flattenStages(
		workflow as T.Flattenable
	)?.some((stage) =>
		stage?.owners?.some(
			(owner) =>
				currentUser?.proxyingFor?._id === owner?._id ||
				owner._id === currentUser?._id ||
				groupsForCurrentUser?.some((grp) => grp._id === owner._id)
		)
	);

	const isWorkflowFollower = workflow?.followers?.some(
		(a) =>
			a._id === currentUser?._id ||
			groupsForCurrentUser.some((grp) => grp._id === a._id)
	);

	const isCreator = workflow?.createdBy?._id === currentUser?._id;

	const isFollower = isUserOwnerOrFollower(
		workflow?.templateUsed as T.WorkflowTemplate,
		currentUser,
		groupsForCurrentUser
	);

	return isStakeholder || isCreator || isFollower || isWorkflowFollower;
};

export const revertWorkflow = (stageToRevert: T.Stage, workflow: T.Workflow) =>
	revertStages(
		workflow?.stages!,
		getRevertedStageIndex(stageToRevert, workflow?.stages!)
	);

export const getUsersStages = (
	workflows: T.Workflow[],
	user: User,
	userGroups: any[],
	assignedDirectlyOnly = false
) => {
	const filteredWorkflows = workflows?.filter(
		(wf) => wf.status !== 'cancelled' && canView(wf, user, userGroups)
	);
	const allStages = getAllStages(filteredWorkflows);
	const userStages = allStages.filter((stage: T.Stage) =>
		userIsOwner(stage, user, userGroups, assignedDirectlyOnly)
	);

	return userStages;
};

export const userIsOwner = (
	stage?: T.Stage,
	user?: User,
	userGroups?: UserGroup[],
	assignedDirectlyOnly = false
) => {
	if (!user || !stage) return false;
	if (userOwnsStage(stage, user)) return true;
	if (assignedDirectlyOnly) return false;
	if (!userGroups) return false;
	if (userGroups && stageInUserGroup(stage, userGroups)) return true;
	return false;
};

const stageInUserGroup = ({ owners }: T.Stage, groups: UserGroup[]) => {
	return groups
		? groups.some(
				({ _id }) =>
					owners && owners.some((o) => o._id.toString() === _id.toString())
		  )
		: false;
};

const userOwnsStage = ({ owners }: T.Stage, currentUser: User) => {
	return owners?.some(R.propEq('_id', currentUser._id));
};

export const getActiveStageNames = (workflow?: T.Workflow) => {
	if (!workflow || !workflow.stages) return '—';
	return (
		workflow.stages
			.filter(({ status }) => status === 'active')
			.map(R.prop('title'))
			.join(', ') || '—'
	);
};

export const canEditWorkflowCollection = (
	collection: T.WorkflowCollection,
	user: User
) => {
	return true;
	// return (
	// 	[UserRole.RomeDevelopers, UserRole.SuperAdmin].some(
	// 		(role) => role === user.role
	// 	) || collection.owners.some((owner) => owner._id === user._id)
	// );
};

export const listActiveStageNames = (workflow: T.Workflow): string => {
	return workflow?.stages?.filter((stage) => stage.status === 'active').length
		? workflow?.stages
				?.filter((stage) => stage.status === 'active')
				.map((stage) => stage.title)
				.join(', ')
		: '—';
};

export const isActionableStage = (
	stage: T.Stage | undefined,
	currentUser: User,
	groups: UserGroup[]
) =>
	!!currentUser &&
	!!stage &&
	stage.type !== 'parallel' &&
	(stage.status === 'active' || stage.status === 'roadblocked') &&
	stage?.owners.some((m) =>
		m.type === 'AccountGroup'
			? groups.some((g) => g._id === m._id)
			: currentUser?.proxyingFor?._id === m._id || currentUser._id === m._id
	);
// {
// 	if (!currentUser) return false;
// 	if (stage.type === 'parallel') return false;
// 	if (R.not(stage.status === 'active' || stage.status === 'roadblocked'))
// 		return false;
// };

export const verifyTransitions = (workflow: T.Workflow) => {
	let status = true;
	return status;
};

/**
 * Return array of stages in workflow that are upstream of a stage
 * @param template WorkflowTemplate
 * @param stage Stage
 * @returns Stage[]
 */
export const getStagesBefore = (template: WorkflowTemplate, stage: Stage) => {
	if (!template || !stage) return [];
	if (
		// ! let's not depend on arbitrary string values
		stage?.title === 'Untitled Stage' &&
		flattenStages(template)?.every((s) => s._id !== stage._id)
	) {
		return flattenStages(template);
	}
	let searchStage: Maybe<Stage>;
	if (stage.type === StageType.sideTask) {
		return template?.sideTasks?.filter(
			(sideTask) => sideTask._id !== stage._id
		);
	}
	if (stage.type === StageType.substage) {
		searchStage = template?.stages?.find((stg: any) => {
			if (
				stg.type === StageType.parallel &&
				stg?.substages?.flatMap((substage: any) =>
					substage.some((sub: any) => sub._id === stage._id)
				)
			) {
				return true;
			}
			return false;
		});
	} else {
		if (template.stages[0] === stage) return [];

		searchStage = stage;
	}

	if (searchStage) {
		const stageIdx = template.stages.findIndex(
			(i) => i._id === searchStage?._id
		) as number;

		if (stageIdx > 0) {
			return template.stages.slice(0, stageIdx);
		}
	}
	return [];
};

/**
 * return eligibale stages for reject path targets (top level stages only)
 * @param template WorkflowTemplate
 * @param stage Stage
 */
export const getRejectionCandidates = (
	template: T.WorkflowTemplate,
	selected_stage: Stage,
	calling_stage?: Stage
): Stage[] => {
	const selectedType = selected_stage.type;

	// * if selected_stage is a substage, then we need to find the top level stage
	// * calling_stage is the stage the the add stage button was in so we can establish
	// * current position in stage sequence

	// * Stage type determines reject path options:
	// * - single (top level): reject to top level stages upstream of self
	// * - substage: reject to stages upstream of parent stage
	// * - parallel: reject to stages upstream of parent stage
	// * - sideTask: reject to other sideTask

	// * base stage is either top level (self) or parent stage of selected substage (all top level singles) or sideTask
	// * rejection options are of the same type as basis stages

	// * Stages with substages should return none
	// * SideTasks should return none if there are no other sideTasks

	// nothing in, nothing out
	if (!template || !selected_stage) {
		return [];
	}

	// does selected_stage have substages? return []
	if (selected_stage.substages?.length) {
		return [];
	}

	// get all stages flattened
	let candidates = flattenStages(template, true);
	let include_calling_stage = false;

	// * if selected_stage is not in all stages, then we are creating a new one
	// * and we need to use the calling_stage to calc basis_stage
	if (!candidates.some((s) => s._id === selected_stage._id) && calling_stage) {
		selected_stage = calling_stage;
		include_calling_stage = !isSubstage(template, calling_stage);
	}


	// calcuate basis stage for selected_stage
	const stageParent = getStageParent(template, selected_stage);
	const basis_stage = stageParent || selected_stage;

	// filter stages based on basis_stage type: single \\ sideTask
	if (basis_stage?.type === StageType.sideTask) {
		candidates = candidates.filter(
			(stage) =>
				stage.type === StageType.sideTask && stage._id !== basis_stage?._id
		);
	} else {
		candidates = candidates.filter((stage) => {
			return (
				stage.type !== StageType.substage &&
				stage.type !== StageType.sideTask &&
				getStageParent(template, stage) === undefined
			);
		});

		candidates = candidates.slice(0,candidates.findIndex((stage) => stage._id === basis_stage._id) +
			(include_calling_stage ? 1 : 0)
		);


		if(selectedType==='substage')
			candidates = candidates.filter(c=>c._id.toString()!==basis_stage._id)
	}

	return candidates;
};
