import { useAccounts } from 'context/useAccounts';
import { AlgoliaAssetVersion } from 'components/dam-assets/components/asset-infinite-hits.component';
import { ToggleCollectionView } from 'components/ToggleCollectionView/ToggleCollectionView';
import { isEqual, orderBy } from 'lodash';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import {
	connectInfiniteHits,
	InfiniteHitsProvided,
} from 'react-instantsearch-core';
import { MenuSelect } from 'react-instantsearch-dom';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import styled from 'styled-components';
import { useSearchHistory } from 'utils/useSearchHandler';
import { CustomClearRefinements } from '../../algolia/clear-refinements';
import {
	AssetSelectionTool,
	AssetSelectionToolbarClickEvent,
	defaultAssetSelectionTools,
} from '../../dam-assets/components/asset-selection-toolbar.component';
import { useAssetHelper } from '../../dam-assets/components/helpers/useAssetHelper';
import { damAssetFolderRefinementSettings } from '../../dam-assets/dam-asset-refinements';
import { SubmitButton } from '../../forms';
import AssetAlgoliaSortby from 'components/dam-assets/components/asset-algolia-sortby';
const AssetListTable = React.lazy(() =>
	import('../../dam-assets/components/asset-list-table.component')
);
const AssetCardGrid = React.lazy(() =>
	import('../../dam-assets/components/asset-card-grid.component')
);
const Loading = React.lazy(() => import('../../loading.component'));
const AssetSelectionToolbar = React.lazy(() =>
	import('../../dam-assets/components/asset-selection-toolbar.component')
);
const CustomFolderRefinementList = React.lazy(() =>
	import('../../algolia/folder-refinement-list.component')
);
const RenderWhen = React.lazy(() => import('../../render-when.component'));
const ArchivedMenuSelect = styled(MenuSelect)`
	border-radius: 12px;
	padding-left: 0.25rem;
	padding-right: 0.25rem;
	margin-right: 0.25rem;
`;
const FolderRow = styled(Row)`
	margin-top: 1rem;
	.ais-ToggleRefinement-labelText {
		padding-left: 10px;
	}
`;
const Folder = styled.div`
	min-width: 220px;
	max-width: 100%;
`;
interface FacetTreeView {
	assetsPerPage?: number;
}
interface AlgoliaDAMAsset {
	objectID: string;
	fileName: string;
	type: string;
	metadata?: any;
	createdAt: Date;
	__queryID: string;
}

export type SortOrder = {
	sort: 'asc' | 'desc';
	field: 'fileName' | 'createdAt' | 'fileSizeBytes';
};
const Tools = styled(Col)`
	display: flex;
	justify-content: flex-end;
`;
interface AssetHits extends InfiniteHitsProvided<AlgoliaAssetVersion> {
	isRefining: boolean;
	isRefiningCallback: (isRefining: boolean) => void;
	availableRefinements: string[];
	permissionsObject?: any;
}

const FacetTreeView = (props: AssetHits) => {
	const [currentView, setCurrentView] = useState<'cards' | 'list'>('list');
	const [, setSearchHistory] = useSearchHistory();
	const [selectedAssets, setSelectedAssets] = useState<
		Array<AlgoliaAssetVersion>
	>([]);
	const [isSelectingAssets, setSelectingAssets] = useState(false);
	const { defaultAct } = useAccounts();
	const { findOne } = useAssetHelper();
	const [currentSort, dispatchCurrentSort] = React.useReducer(function (
		currentSortOrder: SortOrder,
		action: { type: 'asc' | 'desc'; payload?: string }
	) {
		switch (action.type) {
			case 'asc':
				return { sort: action.type, field: action.payload } as SortOrder;
			case 'desc':
				return { sort: action.type, field: action.payload } as SortOrder;
		}
	},
	{} as SortOrder);
	function assetListReducer(
		state: { assets: AlgoliaAssetVersion[] },
		action: { type: 'SET' | 'CLEAR'; payload?: AlgoliaAssetVersion[] }
	) {
		switch (action.type) {
			case 'SET':
				return { ...state, assets: action.payload as AlgoliaAssetVersion[] };
			case 'CLEAR':
				return { ...state, assets: [] };
			default:
				return state;
		}
	}

	/**
	 * This method takes the users available permissions and passes them into the refinement list component
	 * @param attribute facet to be filtered on
	 */

	const getPermissionsForAttribute = (attribute: string): string[] => {
		switch (attribute) {
			case 'metadata.Brand':
				return props.permissionsObject?.brand as string[];
			case 'metadata.Country':
				return props.permissionsObject?.country as string[];
			case 'metadata.Size':
				return props.permissionsObject?.size as string[];
			case 'metadata.Category':
				return props.permissionsObject?.category as string[];
			case 'metadata.Printer':
				return props.permissionsObject?.printer as string[];
			case 'type':
				return props.permissionsObject?.filetype as string[];
			case 'metadata.Variety':
				return props.permissionsObject?.variety as string[];
			case 'metadata.Die':
				return props.permissionsObject?.die as string[];
			case 'metadata.Package Type':
			case 'metadata.Asset Type':
			case 'metadata.Win The Day Materials':
				return props.permissionsObject?.packageType as string[];
			case 'archived':
				return props.permissionsObject?.archived as string[];
			default:
				return [];
		}
	};

	/**
	 * This method filters the hits from algolia mapping them to DAM Assets and also filtering out
	 * any unavailable refinements due to permissions
	 * @param obj The current hits coming back from algolia
	 */
	/**
	 * This method filters the hits from algolia mapping them to DAM Assets and also filtering out
	 * any unavailable refinements due to permissions
	 * @param obj The current hits coming back from algolia
	 */
	const filterAssetsByPermission = useCallback(
		async (obj: AlgoliaAssetVersion[]) => {
			const filterOut = (metadata: any) => {
				if (props.permissionsObject?.brand.length && !!metadata.Brand) {
					if (
						props.permissionsObject?.brand.indexOf(metadata.Brand.trim()) ===
							-1 ||
						!props.permissionsObject.brand.includes(metadata.Brand.trim())
					)
						return false;
				}

				if (props.permissionsObject?.archived.length && !!metadata.Archived) {
					if (
						props.permissionsObject?.archived.indexOf(
							metadata.Archived.toString().trim()
						) === -1 ||
						!props.permissionsObject.archived.includes(
							metadata.Archived.toString().trim()
						)
					)
						return false;
				}

				if (props.permissionsObject?.category.length && !!metadata.Category) {
					if (
						props.permissionsObject?.category.indexOf(
							metadata.Category.trim()
						) === -1 ||
						!props.permissionsObject.category.includes(metadata.Category.trim())
					)
						return false;
				}

				if (props.permissionsObject?.size.length && !!metadata.Size) {
					if (!props.permissionsObject?.size.includes(metadata.Size.trim()))
						return false;
				}

				if (props.permissionsObject?.variety.length && !!metadata.Variety) {
					if (
						!props.permissionsObject?.variety.includes(metadata.Variety.trim())
					)
						return false;
				}
				if (props.permissionsObject?.filetype.length && !!metadata.type) {
					if (!props.permissionsObject?.filetype.includes(metadata.type.trim()))
						return false;
				}

				if (props.permissionsObject?.die.length && !!metadata.Die) {
					if (!props.permissionsObject?.die.includes(metadata.Die.trim()))
						return false;
				}
				if (props.permissionsObject?.printer.length && !!metadata.Printer) {
					if (
						!props.permissionsObject?.printer.includes(metadata.Printer.trim())
					)
						return false;
				}

				return true;
			};

			return Promise.all(
				obj
					.filter((asset: AlgoliaAssetVersion) => {
						return defaultAct?.useDAMUserGroupPermissions
							? !asset?.metadata?.values ||
									(!!asset.metadata.values && filterOut(asset.metadata))
							: true;
					})
					.map(async (asset) => {
						return {
							...(await findOne(asset.objectID.replace('DAMAsset_', ''))),
							__queryID: asset.__queryID,
							objectID: asset.objectID,
							createdAt: new Date((asset.createdAt as number) * 1000),
							_id: asset.objectID.replace('DAMAsset_', ''),
						};
					})
			);
		},
		//eslint-disable-next-line
		[props.permissionsObject, findOne]
	);
	const [assetList, setAssetList] = React.useReducer(assetListReducer, {
		assets: Array<AlgoliaAssetVersion>(),
	});

	const [isLoading, setIsLoading] = useState(false);

	React.useEffect(() => {
		if (!props.isRefining) {
			setAssetList({ type: 'CLEAR' });
		}
	}, [props.isRefining]);

	React.useEffect(() => {
		if (isLoading) return;
		if (
			props.hits.every(
				({ objectID }) =>
					!!assetList.assets.some(
						({ _id }) => _id === objectID.replace('DAMAsset_', '')
					)
			) &&
			!!isEqual(
				orderBy(
					assetList.assets,
					(i) => i[currentSort.field],
					currentSort.sort
				),
				assetList.assets
			) &&
			props.hits.length === assetList.assets.length
		)
			return;

		if (props.hits.length !== assetList.assets.length) {
			setIsLoading(true);
			filterAssetsByPermission(props.hits as AlgoliaAssetVersion[])
				.then((assets) => {
					const filtered = assets;
					if (currentSort && currentSort.sort && currentSort.field)
						setAssetList({
							type: 'SET',
							payload: [
								...orderBy(
									filtered as AlgoliaAssetVersion[],
									(i) => i[currentSort.field] ?? i.title,
									currentSort.sort
								),
							],
						});
					else
						setAssetList({
							type: 'SET',
							payload: [...(filtered as AlgoliaAssetVersion[])],
						});
				})
				.finally(() => setIsLoading(false));
		}
	}, [
		props.hits,
		currentSort,
		filterAssetsByPermission,
		assetList.assets,
		isLoading,
	]);

	useEffect(() => {
		if (!assetList.assets) return;
		if (
			isEqual(
				assetList.assets,
				orderBy(assetList.assets, (a) => a[currentSort.field], currentSort.sort)
			)
		)
			return;
		if (currentSort && currentSort.field && currentSort.sort) {
			const prop =
				currentSort.field === 'fileName' ? 'title' : currentSort.field;
			setAssetList({
				type: 'SET',
				payload: orderBy(assetList.assets, (a) => a[prop], currentSort.sort),
			});
		}
	}, [currentSort, assetList.assets]);

	const toolbarCallBack = useCallback(
		(event: AssetSelectionToolbarClickEvent) => {
			if (event.tool === AssetSelectionTool.SELECTION) {
				const isSelecting: boolean = event.value;
				setSelectingAssets(isSelecting);
				if (!isSelecting) {
					// empty selected assets if selecting is turned off
					setSelectedAssets([]);
				}
			}
		},
		[setSelectingAssets]
	);

	const assetSelectionCallBack = useCallback(
		(asset: AlgoliaAssetVersion) => {
			const index = selectedAssets.indexOf(asset);
			if (index > -1) {
				// remove from selection list
				setSelectedAssets((selectedAssets) =>
					selectedAssets.filter((a) => a._id !== asset._id)
				);
			} else {
				// add to selection list
				setSelectedAssets((selectedAssets) => selectedAssets.concat(asset));
			}
		},
		[selectedAssets]
	);

	return (
		<Suspense fallback={<Loading />}>
			<>
				<Row className="d-flex align-items-end">
					<Tools>
						<AssetSelectionToolbar
							isUsingAlgoliaSort={true}
							AssetAlgoliaSortby={AssetAlgoliaSortby}
							tools={defaultAssetSelectionTools}
							className="asset-index-toolbar mr-4"
							onClick={toolbarCallBack}
							selectedAssets={selectedAssets}
							dispatchCurrentSort={dispatchCurrentSort}
						/>
						<ToggleCollectionView
							currentView={currentView}
							cards={true}
							toggle={() =>
								setCurrentView((v) => (v === 'cards' ? 'list' : 'cards'))
							}
						/>
					</Tools>
				</Row>
				<Row>
					<div className="d-flex justify-content-center align-items-between flex-row flex-wrap">
						<Folder className={`form-horizontal`}>
							<Col
								className={
									'mt-4 d-flex justify-content-center align-items-between flex-row flex-wrap'
								}
								lg={12}
							>
								{damAssetFolderRefinementSettings
									.filter((setting) => setting.attribute !== 'archived')
									.map((refinementSettings) => (
										<CustomFolderRefinementList
											// @ts-ignore
											availableRefinements={getPermissionsForAttribute(
												refinementSettings.attribute
											)}
											isRefining={props.isRefining}
											isRefiningCallback={props.isRefiningCallback}
											key={refinementSettings.attribute}
											{...refinementSettings}
										/>
									))}
							</Col>
						</Folder>
					</div>
				</Row>
				<FolderRow>
					<Col>
						<FormGroup>
							<Form inline className="mt-2">
								<Label>Archived</Label>
								<ArchivedMenuSelect
									attribute="archived"
									defaultRefinement="false"
								/>
								<RenderWhen when={props.isRefining}>
									<CustomClearRefinements
										// @ts-ignore
										clearRefiningCallback={() => {
											props.isRefiningCallback(false);
											setSearchHistory((history: any) => ({
												...history,
												refinements: [],
											}));
										}}
									/>
								</RenderWhen>
							</Form>
						</FormGroup>
					</Col>
				</FolderRow>
				<Row>
					<RenderWhen
						key={'assets'}
						when={assetList.assets.length > 0 && props.isRefining}
					>
						{currentView === 'cards' ? (
							<AssetCardGrid
								onDelete={(a) =>
									setAssetList({
										type: 'SET',
										payload: assetList.assets.filter(
											(asset) => a._id !== asset._id
										),
									})
								}
								assets={assetList.assets}
								areLinks={!isSelectingAssets}
								isSelectionAvailable={true}
								onAssetSelected={assetSelectionCallBack}
								selectedAssets={selectedAssets}
							/>
						) : currentView === 'list' ? (
							<AssetListTable
								onDelete={(a) =>
									setAssetList({
										type: 'SET',
										payload: assetList.assets.filter(
											(asset) => a._id !== asset._id
										),
									})
								}
								assets={assetList.assets}
								isSelectingAssets={isSelectingAssets}
								onAssetSelected={assetSelectionCallBack}
								selectedAssets={selectedAssets}
							/>
						) : null}
					</RenderWhen>
					<RenderWhen when={props.hasMore && props.isRefining}>
						<Col xs={12} className="text-center">
							<SubmitButton label="Show more" onClick={props.refineNext} />
						</Col>
					</RenderWhen>
				</Row>
			</>
		</Suspense>
	);
};
const InfiniteHitsFolderView = connectInfiniteHits(FacetTreeView);
export default InfiniteHitsFolderView;
